/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages




            // Nav
            var position = $(window).scrollTop();
            $(window).scroll(function() {
              var scroll = $(window).scrollTop();
              if (scroll > position) {
                $(".banner").removeClass("smaller");
              } else {
                $(".banner").addClass("smaller");
              }
              position = scroll;
            });

            // ScrollReveal
            window.sr = ScrollReveal();
            sr.reveal('.fadein', {
                viewFactor : 0.2,
                duration: 1000
            });

          // Smooth Scrolling
            $('a[rel="relativeanchor"], .page-anchor').click(function(){
                $('html, body').animate({
                    scrollTop: $( $.attr(this, 'href') ).offset().top - 100
                }, 500);
                return false;
            });

            // Enable Tooltips
            $('[data-toggle="tooltip"]').tooltip();

            // Mobile Menu
            $('.menu-toggle').click(function(){
                $(this).toggleClass('open');
                $('body').toggleClass('nav-open');
                if($(this).hasClass('open')) {
                    $(this).html('<i class="ion-android-close"></i>');
                } else {
                    $(this).html('<i class="ion-navicon"></i>');
                }
            });

            $('.nav-login').click(function() {
                $('#loginModal').modal('show')
            });


            // zip code search on homepage
            jQuery(function ( $ ){
                $('#wpsl-search-button-home').click(function(e) {
                  var url = 'find-a-therapist/?zip=';
                  var inputURL = $('#wpsl-search-input-home').val();
                  window.location.href = url + inputURL;
                  return false;
                });
            });

            // get parameter and post on location search
            jQuery(function ( $ ){
                $.urlParam = function(name){
                    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
                    if (results==null) {
                        return null;
                    } else {
                        return results[1] || 0;
                    }
                }
                if ($.urlParam('zip')) {
                    // set the default value of a text input field
                    $('#wpsl-search-input').val(decodeURIComponent($.urlParam('zip')));
                    // delay and click button
                    setTimeout(function(){
                        $('#wpsl-search-btn').click();
                    },5000);  // In my case 5000 because I have many stores
                }
            });

            $('#wpsl-search-input-home').focus(function() {
                $('.find-block').addClass('active');
            });
            $('#wpsl-search-input-home').blur(function() {
                $('.find-block').removeClass('active');
            });


            var conditionalInput = $('.dup-info');
            var subscribeInput = $('#dup_info input');

            conditionalInput.show();
            subscribeInput.on('click', function(){
                if ( $(this).is(':checked') ) {
                  conditionalInput.fadeOut();
                }
                else {
                  conditionalInput.fadeIn();
                }

            });


            var ckbox = $('#sameContactInfo');
            ckbox.on('click',function () {
              if (ckbox.is(':checked')) {
                console.log('same');
                $('.same-info').slideUp('fast');
              } else {
                $('.same-info').slideDown('fast');
              }
            });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        var hero_slider = $('.slider').slick({
            dots: false,
            arrows: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 600,
            autoplay: false,
            pauseOnHover: false,
            adaptiveHeight: true,
            appendArrows: $('.navs-wrap'),
            prevArrow: "<div class='prev slider-arrow'><i class='ion-ios-arrow-thin-left'></i></div>",
            nextArrow: "<div class='next slider-arrow'><i class='ion-ios-arrow-thin-right'></i></div>"
        });
        var slides = $('.slide').length;

        $('#total').html('4');

        $('.slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
            var current_slide = $('.slick-current').find('.slide-item').data('slide');
            $('#current').html(current_slide);
        });

        $(".slider-menu a").click(function(e){
            e.preventDefault();
            slideIndex = $(this).index();
            hero_slider.slick('slickGoTo', parseInt(slideIndex))
        });

        // News Packery // Filtering
        $('.isotope-wrapper').each(function(){
            var $isotope = $('.isotope-box', this);
            var $filterCheckboxes = $('input[type="radio"]', this);
            var filter = function(){
                var type = $filterCheckboxes.filter(':checked').data('type') || '*';
                if(type !== '*'){
                    type = '[data-type="'+ type +'"]';
                }
                $isotope.isotope({ filter: type });
            };
            $isotope.isotope({
                itemSelector: '.isotope-item',
                layoutMode: 'masonry'
            });
            $(this).on('change', filter);
            filter();
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    'blog': {
      init: function() {
        // JavaScript to be fired on the Events

            $('.isotope-wrapper').each(function(){
                var $isotope = $('.isotope-box', this);
                var $filterCheckboxes = $('input[type="radio"]', this);
                var filter = function(){
                    var type = $filterCheckboxes.filter(':checked').data('type') || '*';
                    if(type !== '*'){
                        type = '[data-type="'+ type +'"]';
                    }
                    $isotope.isotope({ filter: type });
                };
                $isotope.isotope({
                    itemSelector: '.isotope-item',
                    layoutMode: 'masonry'
                });
                $(this).on('change', filter);
                filter();
            });

      }
    },



    'login': {
      init: function() {
        // JavaScript to be fired on the Events

            $('.isotope-wrapper').each(function(){
                var $isotope = $('.isotope-box', this);
                var $filterCheckboxes = $('input[type="radio"]', this);
                var filter = function(){
                    var type = $filterCheckboxes.filter(':checked').data('type') || '*';
                    if(type !== '*'){
                        type = '[data-type="'+ type +'"]';
                    }
                    $isotope.isotope({ filter: type });
                };
                $isotope.isotope({
                    itemSelector: '.isotope-item',
                    layoutMode: 'masonry'
                });
                $(this).on('change', filter);
                filter();
            });

      }
    },


    'edit_profile': {
      init: function() {
        // JavaScript to be fired on the Events
        // $('#wppb-edit-user #email').attr('disabled', 'disabled');
      }
    },

    'jobs': {
      init: function() {
        // JavaScript to be fired on the Events

        $('.dropdown-menu .btn').click(function(event) {
          event.stopPropagation();
          console.log('ha');
        });

        var $container;
        var filters = {};

        $(function () {
            $container = $('#jobs_conatiner');
            var $filterDisplay = $('#filter-display');
            $container.isotope();
            $('#options').on('change', function (jQEvent) {
                var $checkbox = $(jQEvent.target);
                manageCheckbox($checkbox);
                var comboFilter = getComboFilter(filters);
                $container.isotope({
                    filter: comboFilter
                });
                $filterDisplay.text(comboFilter);
            });
        });


        function getComboFilter( filters ) {
          var i = 0;
          var comboFilters = [];
          var message = [];

          for ( var prop in filters ) {
            message.push( filters[ prop ].join(' ') );
            var filterGroup = filters[ prop ];
            if ( !filterGroup.length ) {
              continue;
            }
            if ( i === 0 ) {
              comboFilters = filterGroup.slice(0);
            } else {
              var filterSelectors = [];
              // copy to fresh array
              var groupCombo = comboFilters.slice(0);
              // merge filter Groups
              for (var k=0, len3 = filterGroup.length; k < len3; k++) {
                for (var j=0, len2 = groupCombo.length; j < len2; j++) {
                  filterSelectors.push( groupCombo[j] + filterGroup[k] );
                }
              }
              comboFilters = filterSelectors;
            }
            i++;
          }
          var comboFilter = comboFilters.join(', ');
          return comboFilter;
        }

        function manageCheckbox( $checkbox ) {
          var checkbox = $checkbox[0];

          var group = $checkbox.parents('.option-set').attr('data-group');
          // create array for filter group, if not there yet
          var filterGroup = filters[ group ];
          if ( !filterGroup ) {
            filterGroup = filters[ group ] = [];
          }

          var isAll = $checkbox.hasClass('all');
          // reset filter group if the all box was checked
          if ( isAll ) {
            delete filters[ group ];
            if ( !checkbox.checked ) {
              checkbox.checked = 'checked';
            }
          }
          // index of
          var index = $.inArray( checkbox.value, filterGroup );

          if ( checkbox.checked ) {
            var selector = isAll ? 'input' : 'input.all';
            $checkbox.siblings( selector ).removeAttr('checked');


            if ( !isAll && index === -1 ) {
              // add filter to group
              filters[ group ].push( checkbox.value );
            }

          } else if ( !isAll ) {
            // remove filter from group
            filters[ group ].splice( index, 1 );
            // if unchecked the last box, check the all
            if ( !$checkbox.siblings('[checked]').length ) {
              $checkbox.siblings('input.all').attr('checked', 'checked');
            }
          }

        }

      }
    },

    // Single Events
    'single_events': {
      init: function() {
        // JavaScript to be fired on the Single Events page

        (function($) {
            function new_map( $el ) {
                var $markers = $el.find('.marker');
                var args = {
                    zoom		: 16,
                    center		: new google.maps.LatLng(0, 0),
                    mapTypeId	: google.maps.MapTypeId.ROADMAP
                };
                var map = new google.maps.Map( $el[0], args);
                map.markers = [];
                $markers.each(function(){
                    add_marker( $(this), map );
                });
                center_map( map );
                return map;
            }
            function add_marker( $marker, map ) {
                var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );
                var icon = {
                    url: '../../wp-content/themes/ptpn/dist/images/marker.svg',
                    scaledSize: new google.maps.Size(40, 40), // scaled size
                    origin: new google.maps.Point(0,0), // origin
                    anchor: new google.maps.Point(0, 0) // anchor
                };

                var marker = new google.maps.Marker({
                    position: latlng,
                    map: map,
                    icon: icon
                });
                map.markers.push( marker );
                if( $marker.html() )
                {
                    var infowindow = new google.maps.InfoWindow({
                        content		: $marker.html()
                    });
                    google.maps.event.addListener(marker, 'click', function() {
                        infowindow.open( map, marker );
                    });
                }
            }
            function center_map( map ) {
                var bounds = new google.maps.LatLngBounds();
                $.each( map.markers, function( i, marker ){
                    var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
                    bounds.extend( latlng );
                });
                if( map.markers.length == 1 )
                {
                    map.setCenter( bounds.getCenter() );
                    map.setZoom( 8 );
                }
                else
                {
                    map.fitBounds( bounds );
                }
            }
            var map = null;
            $(document).ready(function(){
                $('.ptpn-map').each(function(){
                    map = new_map( $(this) );
                });
            });
        })(jQuery);

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    // Contact
    'contact': {
      init: function() {
        // JavaScript to be fired on the Contact page

        // Display Map on Contact Page
        (function($) {
            function new_map( $el ) {
                var $markers = $el.find('.marker');
                var args = {
                    zoom		: 16,
                    center		: new google.maps.LatLng(0, 0),
                    mapTypeId	: google.maps.MapTypeId.ROADMAP,
                    styles:                      [
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#444444"
            }
        ]
    },
    {
        "featureType": "administrative.neighborhood",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f2f2f2"
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "hue": "#ff0000"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#515252"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "hue": "#ff0000"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#e8e8e8"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#3f3f3f"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#abedf1"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#b5d4f5"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    }
]
                };
                var map = new google.maps.Map( $el[0], args);
                map.markers = [];
                $markers.each(function(){
                    add_marker( $(this), map );
                });
                center_map( map );
                return map;
            }
            function add_marker( $marker, map ) {
                var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );
                var icon = {
                    url: '../wp-content/themes/ptpn/dist/images/marker.svg',
                    scaledSize: new google.maps.Size(40, 40), // scaled size
                    origin: new google.maps.Point(0,0), // origin
                    anchor: new google.maps.Point(0, 0) // anchor
                };

                var marker = new google.maps.Marker({
                    position: latlng,
                    map: map,
                    icon: icon
                });
                map.markers.push( marker );
                if( $marker.html() )
                {
                    var infowindow = new google.maps.InfoWindow({
                        content		: $marker.html()
                    });
                    google.maps.event.addListener(marker, 'click', function() {
                        infowindow.open( map, marker );
                    });
                }
            }
            function center_map( map ) {
                var bounds = new google.maps.LatLngBounds();
                $.each( map.markers, function( i, marker ){
                    var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
                    bounds.extend( latlng );
                });
                if( map.markers.length == 1 )
                {
                    map.setCenter( bounds.getCenter() );
                    map.setZoom( 16 );
                }
                else
                {
                    map.fitBounds( bounds );
                }
            }
            var map = null;
            $(document).ready(function(){
                $('.ptpn-map').each(function(){
                    map = new_map( $(this) );
                });
            });
        })(jQuery);

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    // About us page, note the change from about-us to about_us.
    'about': {
      init: function() {
        // JavaScript to be fired on the about us page

            // Team Member Info
            $(".post-link").click(function(event) {
                event.preventDefault();
                $("#team-container").html('');
                var post_id = $(this).attr('rel');
                var ajaxURL = '../wp-admin/admin-ajax.php';
                $.ajax({
                    type: 'POST',
                    url: ajaxURL,
                    data: {"action": "load-content", post_id: post_id },
                    success: function(response) {
                        $("#team-container").html(response);
                        return false;
                    },
                    complete: function() {
                        $("#team-container").addClass('active');
                    }
                });
            });



            // ACF MAP
            (function($) {
                function new_map( $el ) {
                    var $markers = $el.find('.marker');
                    var args = {
                        zoom		: 5,
                        center		: new google.maps.LatLng(0, 0),
                        styles      :[
                            {
                                "featureType": "administrative",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#444444"
                                    }
                                ]
                            },
                            {
                                "featureType": "administrative.neighborhood",
                                "elementType": "labels",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "featureType": "landscape",
                                "elementType": "all",
                                "stylers": [
                                    {
                                        "color": "#f2f2f2"
                                    }
                                ]
                            },
                            {
                                "featureType": "landscape.man_made",
                                "elementType": "labels",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "featureType": "landscape.man_made",
                                "elementType": "labels.icon",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    },
                                    {
                                        "hue": "#ff0000"
                                    }
                                ]
                            },
                            {
                                "featureType": "poi",
                                "elementType": "all",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "featureType": "road",
                                "elementType": "all",
                                "stylers": [
                                    {
                                        "saturation": -100
                                    },
                                    {
                                        "lightness": 45
                                    }
                                ]
                            },
                            {
                                "featureType": "road.highway",
                                "elementType": "all",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "featureType": "road.highway",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#515252"
                                    }
                                ]
                            },
                            {
                                "featureType": "road.highway",
                                "elementType": "labels.icon",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "hue": "#ff0000"
                                    }
                                ]
                            },
                            {
                                "featureType": "road.arterial",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#e8e8e8"
                                    }
                                ]
                            },
                            {
                                "featureType": "road.arterial",
                                "elementType": "labels.text",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#3f3f3f"
                                    }
                                ]
                            },
                            {
                                "featureType": "road.arterial",
                                "elementType": "labels.text.stroke",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "featureType": "road.arterial",
                                "elementType": "labels.icon",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "featureType": "transit",
                                "elementType": "all",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "featureType": "water",
                                "elementType": "all",
                                "stylers": [
                                    {
                                        "color": "#abedf1"
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "featureType": "water",
                                "elementType": "geometry.fill",
                                "stylers": [
                                    {
                                        "color": "#b5d4f5"
                                    }
                                ]
                            },
                            {
                                "featureType": "water",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    }
                                ]
                            }
                        ],
                        mapTypeId	: google.maps.MapTypeId.ROADMAP
                    };
                    // create map
                    var map = new google.maps.Map( $el[0], args);
                    // add a markers reference
                    map.markers = [];
                    // add markers
                    $markers.each(function(){
                        add_marker( $(this), map );
                    });
                    // center map
                    center_map( map );
                    // return
                    return map;
                }
                function add_marker( $marker, map ) {
                    // var
                    var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

                    var icon = {
                        url: '../wp-content/themes/ptpn/dist/images/marker.svg',
                        scaledSize: new google.maps.Size(40, 40), // scaled size
                        origin: new google.maps.Point(0,0), // origin
                        anchor: new google.maps.Point(20, 40) // anchor
                    };

                    var marker = new google.maps.Marker({
                        position: latlng,
                        map: map,
                        icon: icon
                    });

                    // add to array
                    map.markers.push( marker );
                    // if marker contains HTML, add it to an infoWindow
                    if( $marker.html() )
                    {
                        // create info window
                        var infowindow = new google.maps.InfoWindow({
                            content		: $marker.html()
                        });
                        // show info window when marker is clicked
                        google.maps.event.addListener(marker, 'click', function() {
                            infowindow.open( map, marker );
                            // Smooth Scrolling
                            $(document).on("click",".page-anchor",function() {
                                $('html, body').animate({
                                    scrollTop: $( $.attr(this, 'href') ).offset().top
                                }, 500);
                                return false;
                            });
                            // Office Map Info
                            $(document).on("click",".office-link",function() {
                                console.log('HEY');
                                event.preventDefault();
                                $("#office-container").removeClass('active')
                                var post_id = $(this).attr('rel');
                                var ajaxURL = '../wp-admin/admin-ajax.php';
                                $.ajax({
                                    type: 'POST',
                                    url: ajaxURL,
                                    data: {"action": "load-office-content", post_id: post_id },
                                    success: function(response) {
                                        $("#office-container").html(response);
                                        return false;
                                    },
                                    complete: function() {
                                        $("#office-container").addClass('active');

                                        // Close Office Tab
                                        $('.close-office').click(function() {
                                            $('#office-container').removeClass('active');
                                            $('#office-container').html('');
                                        });

                                    }
                                });
                            });
                        });
                    }
                }
                function center_map( map ) {
                    // vars
                    var bounds = new google.maps.LatLngBounds();
                    // loop through all markers and create bounds
                    $.each( map.markers, function( i, marker ){
                        var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
                        bounds.extend( latlng );
                    });
                    map.setCenter( bounds.getCenter() );
                    map.setZoom( 4 );
                }
                var map = null;
                jQuery(document).ready(function($){
                    $('.acf-map').each(function(){
                        map = new_map( $(this) );
                    });
                });
            })(jQuery);

      }
    },


    'find_a_therapist': {
      init: function() {

        var map;
        var markers = [];
        var infoWindow;
        var ListContainer;
        var locationSelect;
        var resultHeader;
        var Group;

        function load() {
          map = new google.maps.Map(document.getElementById("map"), {
            center: new google.maps.LatLng(34.052366, -118.248133),
            zoom: 12,
            mapTypeId: 'roadmap',
            mapTypeControlOptions: {style: google.maps.MapTypeControlStyle.DROPDOWN_MENU}
          });
          infoWindow = new google.maps.InfoWindow();
          ListContainer = document.getElementById("ListView");
          locationSelect = document.getElementById("locationSelect");
          // On Click, show the map marker of corresponding event
          $('.event-item').on('click', function() {
            var this_id = $(this).attr('id');
            if (this_id != "none"){
              google.maps.event.trigger(markers[this_id], 'click');
            }
          });
        }

        // Search Locations Function
        window.searchLocations = function() {
          $('.map-wrapper').addClass('active');
          // $('#ListView').addClass('active');
          var address = document.getElementById("addressInput").value;
          var geocoder = new google.maps.Geocoder();
          geocoder.geocode({address: address}, function(results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
              searchLocationsNear(results[0].geometry.location);
            } else {
              alert(address + ' not found');
            }
          });
        }

        // Clear Locations Function
         function clearLocations() {
           infoWindow.close();
           for (var i = 0; i < markers.length; i++) {
             markers[i].setMap(null);
           }
           markers.length = 0;
           locationSelect.innerHTML = "";
           var option = document.createElement("div");
           option.setAttribute("class", "event-item");
           option.setAttribute("id", "none");
           option.innerHTML = "<p>All Results</p>";
           locationSelect.appendChild(option);
         }

         function searchLocationsNear(center) {
           clearLocations();
           var radius = document.getElementById('radiusSelect').value;
           var searchUrl = 'https://www.ptpn.com/locations/phpsqlsearch_genxml.php?lat=' + center.lat() + '&lng=' + center.lng() + '&radius=' + radius;
           // var searchUrl = 'http://localhost:8888/ptpn/locations/phpsqlsearch_genxml.php?lat=' + center.lat() + '&lng=' + center.lng() + '&radius=' + radius;
           console.log(searchUrl);
           downloadUrl(searchUrl, function(data) {
             var xml = parseXml(data);
             var markerNodes = xml.documentElement.getElementsByTagName("marker");
             var bounds = new google.maps.LatLngBounds();
             for (var i = 0; i < markerNodes.length; i++) {
               // Create Variables for Map Marker Info
               var post_id = markerNodes[i].getAttribute("user_id");
               var name = markerNodes[i].getAttribute("name");
               var address = markerNodes[i].getAttribute("address");
               // Contact
               var phone_number = markerNodes[i].getAttribute("phone_number");
               var link = markerNodes[i].getAttribute("url");
               // Services
               var physical_therapy = markerNodes[i].getAttribute("physical_therapy")
               var occupational_therapy = markerNodes[i].getAttribute("occupational_therapy")
               var speech_therapy = markerNodes[i].getAttribute("speech_therapy")
               var hand_therapy = markerNodes[i].getAttribute("hand_therapy")
               var pediatrics_therapy = markerNodes[i].getAttribute("pediatrics_therapy")

                 // Services
                 if(physical_therapy == 'T') {
                   var service_1 = 'Physical Therapy <br>';
                 } else {
                   var service_1 = '';
                 }
                 if(occupational_therapy == 'T') {
                   var service_2 = 'Occupational Therapy <br>';
                 } else {
                   var service_2 = '';
                 }
                 if(speech_therapy == 'T') {
                   var service_3 = 'Speech Therapy <br>';
                 } else {
                   var service_3 = '';
                 }
                 if(hand_therapy == 'T') {
                   var service_4 = 'Hands Therapy <br>';
                 } else {
                   var service_4 = '';
                 }
                 if(pediatrics_therapy == 'T') {
                   var service_5 = 'Pediatrics Therapy <br>';
                 } else {
                   var service_5 = '';
                 }
                 //Link
                 if(link !== null && link !== '') {

                   const substring = "http";

                   console.log(link.includes(substring));
                   if(link.includes(substring)==1) {
                     var btn = '<a class="btn btn-sm btn-primary" href="'+link+'" target="_blank">Visit Site</a>';
                   } else {
                     var btn = '<a class="btn btn-sm btn-primary" href="http://'+link+'" target="_blank">Visit Site</a>';
                   }


                 } else {
                   var btn = '';
                 }

               var distance = parseFloat(markerNodes[i].getAttribute("distance"));
               var latlng = new google.maps.LatLng(
                  parseFloat(markerNodes[i].getAttribute("lat")),
                  parseFloat(markerNodes[i].getAttribute("lng")));

                 ListViewhtml(latlng, name, address, distance);
                 createOption(post_id, name, distance, i, link);
                 createMarker(post_id, latlng, name, address, distance, i, link);
                 bounds.extend(latlng);

             function createOption() {
               var option = document.createElement("div");
               option.setAttribute("class", "event-item");
               option.setAttribute("id", i);
               option.innerHTML = "<h5>"+name+"</h5><p class='address'>" + address + "</p><p class='address'>Phone: "+phone_number+"</p><p class='services-list'><strong>Services:</strong><br>"+service_1+service_2+service_3+service_4+service_5+"</p>"+btn;
               locationSelect.appendChild(option);
             }

             function createMarker() {
               var html = "<div class='event-item item-marker'><h5>" + name + "</h5><p class='address'>" + address + "</p><p class='address'>Phone: "+phone_number+"</p><p class='services-list'><strong>Services:</strong><br>"+service_1+service_2+service_3+service_4+service_5+"</p>"+btn+"</div>";
               // create marker
               var icon = {
                 url: '../wp-content/themes/ptpn/dist/images/marker.svg',
                 scaledSize: new google.maps.Size(40, 40), // scaled size
                 origin: new google.maps.Point(0,0), // origin
                 anchor: new google.maps.Point(0, 0) // anchor
               };
               var marker = new google.maps.Marker({
                 map: map,
                 icon: icon,
                 position: latlng
               });

               google.maps.event.addListener(marker, 'click', function() {
                 map.setCenter(marker.getPosition());
                 infoWindow.setContent(html);
                 infoWindow.open(map, marker);
                 $('.map-anchor').click(function(){
                   $('html, body').animate({
                       scrollTop: $( $.attr(this, 'href') ).offset().top - 100
                   }, 500);
                   return false;
                 });
               });
               markers.push(marker);
             }
           }
           map.fitBounds(bounds);
           $('.event-item').on('click', function() {
             var this_id = $(this).attr('id');
             google.maps.event.trigger(markers[this_id], 'click');
           });
          });
        }





        function ListViewhtml(latlng, name, address, distance, link) {
    	     var ListContainer = document.getElementById("ListView");


    	      var ListView =
            '<article class="search-info">'+
            '<h2><a class="marker" href="'+link+'">' + name + '</a></h1>'+
    				'<div class="text">'+
    			  '<p><i class="ion-home"></i> '+ address +'</p>'
    			  '<a class="btn btn-sm" href="'+link+'">View Details</a>  Miles: <strong>' + distance.toFixed(1) + '</strong>'+
    			  '</div>'+
			      '</article>';
            $("#ListView").append(ListView);
        }

        function downloadUrl(url, callback) {
          var request = window.ActiveXObject ?
            new ActiveXObject('Microsoft.XMLHTTP') :
            new XMLHttpRequest;
          request.onreadystatechange = function() {
            if (request.readyState == 4) {
              request.onreadystatechange = doNothing;
              callback(request.responseText, request.status);
            }
          };
          request.open('GET', url, true);
          request.send(null);
        }

        function parseXml(str) {
          if (window.ActiveXObject) {
            var doc = new ActiveXObject('Microsoft.XMLDOM');
            doc.loadXML(str);
            return doc;
          } else if (window.DOMParser) {
            return (new DOMParser).parseFromString(str, 'text/xml');
          }
        }

        function doNothing() {}

        $(document).ready(function() {
          load();
        });

        $('#addressInput').keypress(function (e) {
         var key = e.which;
         if(key == 13)
          {
            $("#search_btn").click();
          }
        });


        // searchLocations();
        $(document).ready(function(){
          $("#search_btn").click();
        });


      }
    },

    'post_type_archive_members': {
      init: function() {
        // JavaScript to be fired on the Members archive page

            // ACF MAP
            (function($) {
                function new_map( $el ) {
                    var $markers = $el.find('.marker');
                    var args = {
                        zoom		: 12,
                        center		: new google.maps.LatLng(0, 0),
                        mapTypeId	: google.maps.MapTypeId.ROADMAP
                    };
                    // create map
                    var map = new google.maps.Map( $el[0], args);
                    // add a markers reference
                    map.markers = [];
                    // add markers
                    $markers.each(function(){
                        add_marker( $(this), map );
                    });
                    // center map
                    center_map( map );
                    // return
                    return map;
                }
                function add_marker( $marker, map ) {
                    // var
                    var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );
                    // create marker
                    var icon = {
                        url: '../wp-content/themes/ptpn/dist/images/marker.svg',
                        scaledSize: new google.maps.Size(40, 40), // scaled size
                        origin: new google.maps.Point(0,0), // origin
                        anchor: new google.maps.Point(0, 0) // anchor
                    };

                    var marker = new google.maps.Marker({
                        position: latlng,
                        map: map,
                        icon: icon
                    });
                    // add to array
                    map.markers.push( marker );
                    // if marker contains HTML, add it to an infoWindow
                    if( $marker.html() )
                    {
                        // create info window
                        var infowindow = new google.maps.InfoWindow({
                            content		: $marker.html()
                        });
                        // show info window when marker is clicked
                        google.maps.event.addListener(marker, 'click', function() {
                            infowindow.open( map, marker );
                            // Smooth Scrolling
                            $(document).on("click",".page-anchor",function() {
                                $('html, body').animate({
                                    scrollTop: $( $.attr(this, 'href') ).offset().top
                                }, 500);
                                return false;
                            });
                            // Office Map Info
                            $(document).on("click",".office-link",function() {
                                console.log('HEY');
                                event.preventDefault();
                                $("#office-container").removeClass('active')
                                var post_id = $(this).attr('rel');
                                var ajaxURL = '../wp-admin/admin-ajax.php';
                                $.ajax({
                                    type: 'POST',
                                    url: ajaxURL,
                                    data: {"action": "load-office-content", post_id: post_id },
                                    success: function(response) {
                                        $("#office-container").html(response);
                                        return false;
                                    },
                                    complete: function() {
                                        $("#office-container").addClass('active');
                                    }
                                });
                            });
                        });
                    }
                }
                function center_map( map ) {
                    // vars
                    var bounds = new google.maps.LatLngBounds();
                    // loop through all markers and create bounds
                    $.each( map.markers, function( i, marker ){
                        var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
                        bounds.extend( latlng );
                    });
                    // only 1 marker?
                    if( map.markers.length == 1 )
                    {
                        // set center of map
                        map.setCenter( bounds.getCenter() );
                        map.setZoom( 8 );
                    }
                    else
                    {
                        map.setCenter( bounds.getCenter() );
                        map.setZoom( 8 ); // Change the zoom value as required
                    }
                }
                var map = null;
                jQuery(document).ready(function($){
                    $('.acf-map').each(function(){
                        map = new_map( $(this) );
                    });
                });
            })(jQuery);

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
